import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import GalleryCard from "./gallery-card"
import metaData from "../../metadata/artwork-metadata"

const Gallery = () => {
  const data = useStaticQuery(graphql`
  query {
    a: file(relativePath: { eq: "artwork/Breakwater.jpeg" }) {
      ...galleryImage
    }
    b: file(relativePath: { eq: "artwork/BrightonBeachIII.jpeg" }) {
      ...galleryImage
    }
    c: file(relativePath: { eq: "artwork/FieldsofGold.jpeg" }) {
      ...galleryImage
    }
    d: file(relativePath: { eq: "artwork/Gull.jpeg" }) {
      ...galleryImage
    }
    e: file(relativePath: { eq: "artwork/Heatwave.jpeg" }) {
      ...galleryImage
    }
    f: file(relativePath: { eq: "artwork/Maying(Full).jpeg" }) {
      ...galleryImage
    }
    g: file(relativePath: { eq: "artwork/Moomin.jpeg" }) {
      ...galleryImage
    }
    h: file(relativePath: { eq: "artwork/Soar.jpeg" }) {
      ...galleryImage
    }
    i: file(relativePath: { eq: "artwork/Stargazy.jpeg" }) {
      ...galleryImage
    }
    j: file(relativePath: { eq: "artwork/StudyofSnowontheDowns.jpeg" }) {
      ...galleryImage
    }
  }
`);
  console.log(data);

  if (data) {
    const cards = Object.keys(data).map(function(value, index) {
      return <GalleryCard
        key={ index }
        pieceName={ metaData[value].name }
        picture={ data[value] }
        status={ metaData[value].status }
        medium={ metaData[value].medium }
        year={ metaData[value].year }
      ></GalleryCard>
    });
    console.log(cards)

    return cards;
  }
  return <div><div>No listed artwork</div></div>
}

export default Gallery;
