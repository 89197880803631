import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/utilities/seo"
import Gallery from "../components/galleries/gallery/gallery-data"

import "../styles/pages/gallery.css"

const GalleryPage = () => ( 
  <Layout>
    <SEO title="Gallery" />
    <h1 className='main-title'>Gallery</h1>
    <div className='gallery-container flex-container'>
      <Gallery/>
    </div>
    <Link to="/">Back to the homepage</Link>
  </Layout>
)

export default GalleryPage;