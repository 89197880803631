import React, { useState } from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";
import "../../../styles/components/gallery.css";

const GalleryCard = ({ pieceName, picture, status, medium, year }) => {
  const [fullscreen, setFullscreen] = useState(false);
  const className = `gallery--card-wrapper${fullscreen ? " fullscreen" : ""}`;

  const toggleFullscreen = () => {
    setFullscreen((current) => {
      return !current;
    });
  };

  return (
    <div className={className}>
      <div className='gallery--card' onClick={toggleFullscreen}>
        <Img className='gallery--card--image' fluid={picture.childImageSharp.fluid} />
        <div className='info' onClick={toggleFullscreen}>
          <h5>{pieceName}</h5>
          <p>{medium}</p>
          <p>{year}</p>
          <div className='status'>
            <p>{status}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

GalleryCard.propTypes = {
  pieceName: PropTypes.string,
};

GalleryCard.defaultProps = {
  pieceName: `Untitled`,
};

export default GalleryCard;
