export const metaData = {
  a: {
    name: 'Breakwater',
    medium: 'Acrylic on Canvas',
    year: 2020,
    status: 'For Sale',
  },
  b: {
    name: 'Brighton Beach III',
    medium: 'Acrylic on Canvas',
    year: 2010,
    status: 'For Sale',
  },
  c: {
    name: 'Fields Of Gold',
    medium: 'Acrylic on Canvas',
    year: 2020,
    status: 'For Sale',
  },
  d: {
    name: 'Gull',
    medium: 'Acrylic on Canvas',
    year: 2020,
    status: 'For Sale',
  },
  e: {
    name: 'Heatwave',
    medium: 'Acrylic on Canvas',
    year: 2010,
    status: 'For Sale',
  },
  f: {
    name: 'Maying',
    medium: 'Acrylic on Canvas',
    year: 2020,
    status: 'For Sale',
  },
  g: {
    name: 'Moomin',
    medium: 'Acrylic on Canvas',
    year: 2020,
    status: 'For Sale',
  },
  h: {
    name: 'Soar',
    medium: 'Acrylic on Canvas',
    year: 2018,
    status: 'For Sale',
  },
  i: {
    name: 'Stargazy',
    medium: 'Acrylic on Canvas',
    year: 2018,
    status: 'For Sale',
  },
  j: {
    name: 'Study of Snow on the Downs',
    medium: 'Acrylic on Canvas',
    year: 2018,
    status: 'For Sale',
  },
}

export default metaData;
